import { gql } from "@apollo/client";

/**
 * Register customer mutation query.
 */
const PASSWORD_RECOVERY = gql`
    mutation PasswordRecovery( $input: SendPasswordResetEmailInput! ) {
        sendPasswordResetEmail( input:$input ) {
            clientMutationId
            user {
              email
              username
            }
        }
    }
`;

export default PASSWORD_RECOVERY;
